import React, { useEffect, useState } from "react";
import toastSuccessIcon from "../../assets/images/toast-success.svg";
import toastErrorIcon from "../../assets/images/toast-error.svg";
import { ToastContainer } from "react-bootstrap";
import CloseSvg from "../../assets/images/close.svg";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { resetToaster } from "../../store/slices/toaster/toasterslice";

const Toaster = ({
  isHeader = false,
  text = "",
  position = "top-center",
  headerText = "",
  delay = "3000",
}) => {
  const dispatch = useDispatch();
  const showToaster = useSelector((state) => state.ars.toasterReducer);

  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    let timer;
    setType(showToaster.type);
    setShow(showToaster.show);
    setTitle(showToaster.title);
    if (showToaster.show) {
      timer = setTimeout(() => {
        setShow(false);
        dispatch(resetToaster());
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showToaster, delay]);

  return (
    <ToastContainer>
      <Toast
        // bg={showToaster.type.toLowerCase() || type.toLowerCase()}
        onClose={() => setShow(false)}
        animation
        show={show}
        delay={showToaster.delay || delay}
        autohide
      >
        <Toast.Body>
          {/* {showToaster.message || text} */}
          <div className="toast-wrapper">
            {type === "success" ? (
              <>
                {(showToaster.isHeader || isHeader) && (
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      onClick={() => setShow(false)}
                    />
                    <strong className="me-auto">{headerText}</strong>
                  </Toast.Header>
                )}
                <span>
                  <img src={toastSuccessIcon} alt="success" />
                </span>
                <div className="toast-content">
                  <h6>{type}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                />
              </>
            ) : type === "notify" ? (
              <>
                <span className="primary">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#333333"
                    xmlns="httpwww.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2667 4.99C14.207 2.06 12.2838 0 10.5862 0C10.3213 0 10.1054 0.05 9.88955 0.11C9.40874 0.27 8.98681 0.65 8.72187 1.14C5.52303 4.83 1.10745 6.51 1.05839 6.51C-0.00135174 6.89 -0.325161 8.62 0.361707 10.52C0.950451 12.15 2.01019 13.18 2.97181 13.18C3.07974 13.18 3.23674 13.18 3.34468 13.13C3.45261 13.08 4.04136 12.97 5.1011 12.8L6.16084 15.08C6.2099 15.19 6.31783 15.3 6.48465 15.35L8.192 15.95C8.24107 15.95 8.29994 16 8.349 16C8.45694 16 8.56487 15.95 8.67281 15.89C8.82981 15.78 8.88868 15.56 8.88868 15.4L8.51581 12.31C9.8503 12.2 11.332 12.15 12.8235 12.26H12.9314C13.1473 12.31 13.3533 12.37 13.5692 12.37C13.8341 12.37 14.05 12.32 14.2659 12.26C15.1686 11.93 15.8064 10.9 15.9732 9.44C16.0812 8.14 15.8653 6.51 15.2766 4.99H15.2667ZM7.01451 14.37L6.21971 12.58L7.44626 12.42L7.7112 14.59L7.01451 14.37ZM10.8512 9.17C10.4783 8.57 10.1054 7.87 9.79142 7C9.52649 6.19 9.31062 5.43 9.26155 4.67C9.41855 4.67 9.63443 4.83 9.8503 5C10.2232 5.38 10.596 5.98 10.8119 6.63C11.2338 7.88 11.1357 8.85 10.861 9.18L10.8512 9.17ZM3.07974 12.09H3.03068C2.65781 12.2 1.863 11.49 1.32332 10.14C0.842515 8.78 1.05839 7.65 1.37238 7.54C1.52938 7.49 4.99316 6.18 8.13313 3.26C8.08407 4.51 8.29013 5.97 8.77094 7.38C9.35968 8.95 10.1545 10.25 11.0572 11.18C6.90658 11.13 3.23674 12.05 3.06993 12.1L3.07974 12.09ZM14.8448 9.33C14.7369 10.36 14.364 11.01 13.8341 11.23C13.7262 11.28 13.6183 11.28 13.5103 11.28C12.9216 11.28 12.2347 10.85 11.489 9.98C12.2347 9.33 12.3917 7.86 11.8128 6.24C11.489 5.43 11.067 4.72 10.5862 4.23C10.1643 3.8 9.68349 3.58 9.20268 3.58C9.20268 3.36 9.20268 3.2 9.25174 3.04C9.35968 2.01 9.73255 1.36 10.2624 1.14C10.3704 1.09 10.4783 1.09 10.5862 1.09C11.646 1.09 13.3043 2.72 14.2561 5.37C14.7859 6.73 15.0018 8.19 14.8448 9.33Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
                <div className="toast-content">
                  <h6>{title}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                />
              </>
            ) : (
              <>
                <div className="toast-error-bg">
                  <span>
                    <img src={toastErrorIcon} alt="error" />
                  </span>
                </div>
                <div className="toast-content">
                  <h6>{type}!</h6>
                  <p>{showToaster.message || text}</p>
                </div>
                <img
                  src={CloseSvg}
                  onClick={() => setShow(false)}
                  className="toast-close"
                />
              </>
            )}
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Toaster;

